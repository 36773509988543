var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApiConnector, deepAssign, dotsToObject, isObjectEmpty, ModelDataTypeImageRenderer, uniqueArray, } from "components-care";
import BackendHttpClient from "./BackendHttpClient";
import ExcelExportIcon from "../../components/icons/ExcelExportIcon";
import i18n from "../../i18n";
import AuthMode from "components-care/dist/backend-integration/Connector/AuthMode";
import { isSessionValid } from "../../pages/components/AuthProvider";
class BackendConnector extends ApiConnector {
    /**
     * Initializes the backend connector
     * @param controller The backend controller which should be used as endpoint
     * @param putTag Top level tag name for data in PUT/POST requests or NULL for no top level tag
     * @param includedRelations A map of field name -> relation name which should be loaded in read(...)
     *                          Example:
     *                          staff_ids -> [staff, staffs]
     *                          device_ids -> [catalog, catalogs]
     * @param additionalQueryParameters Additional GET query parameters added to every request
     * @param additionalOptions Other additional options to change the behaviour of the connector
     */
    constructor(controller, putTag = null, includedRelations = {}, additionalQueryParameters, additionalOptions) {
        super();
        this.getApiBase = (record, action) => {
            var _a;
            if (!record)
                return this.apiBase;
            if (action === "delete" && this.additionalOptions.overrideRecordBaseDelete)
                return this.additionalOptions.overrideRecordBaseDelete;
            return (_a = this.additionalOptions.overrideRecordBase) !== null && _a !== void 0 ? _a : this.apiBase;
        };
        this.convertSort = (sort) => ({
            property: sort.field,
            direction: sort.direction < 0 ? "DESC" : "ASC",
        });
        this.toAgGridFilterType = (filterType) => {
            switch (filterType) {
                case "string":
                case "localized-string":
                case "enum":
                    return "text";
                case "number":
                    return "number";
                case "date":
                    return "date";
                case "datetime":
                    return "datetime";
                case "boolean":
                    return "bool";
                default:
                    throw new Error("not supported by backend");
            }
        };
        this.toAgGridFilterDef = (filter, filterType) => ({
            filterType,
            type: filter.type,
            [filterType === "date"
                ? "dateFrom"
                : filterType === "datetime"
                    ? "dateTimeFrom"
                    : "filter"]: ["inSet", "notInSet"].includes(filter.type)
                ? filter.value1.split(",")
                : filter.value1,
            [filterType === "date"
                ? "dateTo"
                : filterType === "datetime"
                    ? "dateTimeTo"
                    : "filterTo"]: filter.value2 || undefined,
        });
        this.isFilterValid = (filter) => {
            if (!filter)
                return false;
            if (!filter.value1)
                return false;
            if (filter.type === "inRange" && !filter.value2)
                return false;
            return true;
        };
        this.dataGridExporters = [
            {
                id: "excel",
                icon: ExcelExportIcon,
                getLabel: () => i18n.t("data-grid.export.excel.label"),
                getWorkingLabel: () => i18n.t("data-grid.export.excel.working"),
                getReadyLabel: () => i18n.t("data-grid.export.excel.ready"),
                getErrorLabel: () => i18n.t("data-grid.export.excel.error"),
                onRequest: (quickFilter, additionalFilters, fieldFilter, sort, columns) => __awaiter(this, void 0, void 0, function* () {
                    const indexParams = this.getIndexParams(null, null, sort, quickFilter, fieldFilter, additionalFilters, {
                        "export[columns]": columns.map((col) => col.field),
                        locale: i18n.language,
                    }, undefined, columns);
                    const resp = yield BackendHttpClient.get(this.getApiBase(false, "index") + ".xlsx", indexParams, this.getAuthMode());
                    return resp.meta.msg.url;
                }),
                onDownload: (data) => {
                    window.open(data);
                },
            },
        ];
        this.apiBase = "/api/" + controller;
        this.putTag = putTag;
        this.includedRelations = includedRelations;
        this.includedRelationsReverse = {};
        this.additionalQueryParameters = additionalQueryParameters;
        this.additionalOptions = additionalOptions !== null && additionalOptions !== void 0 ? additionalOptions : {};
        if (this.additionalOptions.overrideRecordBase) {
            this.additionalOptions.overrideRecordBase =
                "/api/" + this.additionalOptions.overrideRecordBase;
        }
        if (this.additionalOptions.overrideRecordBaseDelete) {
            this.additionalOptions.overrideRecordBaseDelete =
                "/api/" + this.additionalOptions.overrideRecordBaseDelete;
        }
        if (this.additionalQueryParameters &&
            "include" in this.additionalQueryParameters) {
            throw new Error("include cannot be set via additionalQueryParameters, use includedRelations struct instead");
        }
        Object.entries(this.includedRelations).forEach(([field, meta]) => {
            const type = meta[0];
            if (type in this.includedRelationsReverse) {
                this.includedRelationsReverse[type].push(field);
            }
            else {
                this.includedRelationsReverse[type] = [field];
            }
        });
    }
    getAuthMode() {
        return this.optionalAuth
            ? isSessionValid()
                ? AuthMode.Try
                : AuthMode.Off
            : AuthMode.On;
    }
    getIndexParams(page, rows, sort, quickFilter, gridFilter, additionalFilters, extraParams, model, columns, pageIsOffset) {
        if (!extraParams)
            extraParams = {};
        const dataGridColumns = gridFilter && (columns !== null && columns !== void 0 ? columns : model === null || model === void 0 ? void 0 : model.toDataGridColumnDefinition(true));
        return Object.assign(Object.assign({ [pageIsOffset ? "page[padding]" : "page[number]"]: page !== null && page !== void 0 ? page : undefined, "page[limit]": rows !== null && rows !== void 0 ? rows : undefined, sort: JSON.stringify(sort.map(this.convertSort)), quickfilter: quickFilter, gridfilter: Object.fromEntries(Object.entries(gridFilter).map(([field, filter]) => {
                if (!this.isFilterValid(filter))
                    return [field, undefined];
                const filterTypeCC = dataGridColumns.find((entry) => entry.field === field).type;
                const filterType = this.toAgGridFilterType(filterTypeCC);
                const agGridFilter = this.isFilterValid(filter.nextFilter)
                    ? {
                        condition1: this.toAgGridFilterDef(filter, filterType),
                        condition2: this.toAgGridFilterDef(filter.nextFilter, filterType),
                        filterType,
                        operator: filter.nextFilterType.toUpperCase(),
                    }
                    : this.toAgGridFilterDef(filter, filterType);
                return [
                    filterTypeCC === "localized-string"
                        ? `${field.replace("_translations", "")}.${i18n.language.split("-")[0]}`
                        : field,
                    agGridFilter,
                ];
            })) }, additionalFilters), extraParams, this.additionalQueryParameters);
    }
    index(params, model) {
        return __awaiter(this, void 0, void 0, function* () {
            // load reasonable defaults if nothing is set
            if (!params)
                params = {};
            if (!params.page)
                params.page = 1;
            if (!params.rows)
                params.rows = 25;
            if (!params.sort)
                params.sort = [];
            if (!params.quickFilter)
                params.quickFilter = "";
            if (!params.fieldFilter)
                params.fieldFilter = {};
            if (!params.additionalFilters)
                params.additionalFilters = {};
            const indexParams = this.getIndexParams(params.page, params.rows, params.sort, params.quickFilter, params.fieldFilter, params.additionalFilters, undefined, model);
            return this.indexCommon(indexParams, model);
        });
    }
    index2(params, model) {
        return __awaiter(this, void 0, void 0, function* () {
            // load reasonable defaults if nothing is set
            if (!params.sort)
                params.sort = [];
            if (!params.quickFilter)
                params.quickFilter = "";
            if (!params.fieldFilter)
                params.fieldFilter = {};
            if (!params.additionalFilters)
                params.additionalFilters = {};
            const indexParams = this.getIndexParams(params.offset, params.rows, params.sort, params.quickFilter, params.fieldFilter, params.additionalFilters, undefined, model, undefined, true);
            return this.indexCommon(indexParams, model);
        });
    }
    indexCommon(indexParams, model) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.additionalOptions.singleton)
                throw new Error("Backend connector in singleton mode, index disabled");
            const resp = yield BackendHttpClient.get(this.getApiBase(false, "index"), indexParams, this.getAuthMode());
            return [
                yield Promise.all(resp.data.map((entry) => this.completeAttributes(Object.assign({}, entry.attributes, { id: entry.id }, entry.links), model))),
                {
                    totalRows: resp.meta.total,
                },
                resp.meta,
            ];
        });
    }
    getQueryParameters() {
        var _a;
        return isObjectEmpty(this.includedRelations)
            ? ((_a = this.additionalQueryParameters) !== null && _a !== void 0 ? _a : null)
            : Object.assign(Object.assign({}, this.additionalQueryParameters), { include: uniqueArray(Object.values(this.includedRelations).map((entry) => entry[1])).join(",") });
    }
    processDataResponse(resp, model) {
        return __awaiter(this, void 0, void 0, function* () {
            const included = {};
            if (resp.included) {
                resp.included.forEach((entry) => {
                    var _a;
                    const data = Object.assign({}, entry.attributes, { id: entry.id }, entry.links);
                    const listOfFields = (_a = this.includedRelationsReverse[entry.type]) !== null && _a !== void 0 ? _a : [];
                    listOfFields.forEach((field) => {
                        if (field in included) {
                            included[field].push(data);
                        }
                        else {
                            included[field] = [data];
                        }
                    });
                });
            }
            const relationIds = {};
            if ("relationships" in resp.data && resp.data.relationships) {
                Object.values(resp.data.relationships)
                    .map((data) => data.data)
                    .flat()
                    .filter((entry) => entry)
                    .forEach((entry) => {
                    const fieldName = entry.type + "_ids";
                    if (fieldName in relationIds) {
                        relationIds[fieldName].push(entry.id);
                    }
                    else {
                        relationIds[fieldName] = [entry.id];
                    }
                });
            }
            return [
                yield this.completeAttributes(Object.assign({}, relationIds, resp.data.attributes, { id: resp.data.id }, resp.data.links), model),
                included,
                resp.meta,
            ];
        });
    }
    completeAttributes(data, model) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!model)
                return data;
            const defaults = {};
            // obtain defaults
            for (const key in model.fields) {
                if (!Object.prototype.hasOwnProperty.call(model.fields, key))
                    continue;
                defaults[key] = yield ((_a = model.fields[key].getDefaultValue) !== null && _a !== void 0 ? _a : model.fields[key].type.getDefaultValue)();
            }
            // overwrite defaults by actual data
            return deepAssign({}, dotsToObject(defaults), data);
        });
    }
    create(data, model) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.additionalOptions.singleton)
                throw new Error("BackendConnector is in singleton mode, create disabled");
            const resp = yield BackendHttpClient.post(this.getApiBase(true, "create"), this.getQueryParameters(), this.putTag ? { [this.putTag]: data } : { data }, this.getAuthMode());
            return this.processDataResponse(resp, model);
        });
    }
    read(id, model) {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield BackendHttpClient.get(this.additionalOptions.singleton
                ? this.getApiBase(true, "show")
                : `${this.getApiBase(true, "show")}/${id}`, this.getQueryParameters(), this.getAuthMode());
            return this.processDataResponse(resp, model);
        });
    }
    update(data, model) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            // remove not updated images
            if (model) {
                for (const keyRaw in data) {
                    if (!Object.prototype.hasOwnProperty.call(data, keyRaw))
                        continue;
                    const key = keyRaw;
                    if (((_a = model.fields[key]) === null || _a === void 0 ? void 0 : _a.type) instanceof ModelDataTypeImageRenderer) {
                        if (data[key] && !data[key].startsWith("data:")) {
                            delete data[key];
                        }
                    }
                }
            }
            const resp = yield BackendHttpClient.put(this.additionalOptions.singleton
                ? this.getApiBase(true, "update")
                : `${this.getApiBase(true, "update")}/${data.id}`, this.getQueryParameters(), this.putTag ? { [this.putTag]: data } : { data }, this.getAuthMode());
            return this.processDataResponse(resp, model);
        });
    }
    delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            return BackendHttpClient.delete(this.additionalOptions.singleton
                ? this.getApiBase(true, "delete")
                : `${this.getApiBase(true, "delete")}/${id}`, (_a = this.additionalQueryParameters) !== null && _a !== void 0 ? _a : null, this.getAuthMode());
        });
    }
    deleteMultiple(ids) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (ids.length === 0)
                return;
            return BackendHttpClient.delete(`${this.getApiBase(true, "delete")}/${ids.join(",")}`, (_a = this.additionalQueryParameters) !== null && _a !== void 0 ? _a : null, this.getAuthMode());
        });
    }
    setApiEndpoint(url) {
        this.apiBase = "/api/" + url;
    }
}
export default BackendConnector;
