import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Apple, Facebook, Google, Microsoft, Twitter } from "mdi-material-ui";
import { useTranslation } from "react-i18next";
import { useLocation } from "components-care";
import i18n from "../../../i18n";
const { REACT_APP_OAUTH_FACEBOOK, REACT_APP_OAUTH_TWITTER, REACT_APP_OAUTH_GOOGLE, REACT_APP_OAUTH_MICROSOFT, REACT_APP_OAUTH_APPLE, } = process.env;
export const enableSocialLogins = () => REACT_APP_OAUTH_FACEBOOK === "y" ||
    REACT_APP_OAUTH_TWITTER === "y" ||
    REACT_APP_OAUTH_GOOGLE === "y" ||
    REACT_APP_OAUTH_MICROSOFT === "y" ||
    REACT_APP_OAUTH_APPLE === "y";
const useStyles = makeStyles()((theme) => ({
    socialLogin: {
        border: "solid 1px #3f51b5",
        padding: 4,
        color: theme.palette.primary.main,
        width: 40,
        height: 40,
    },
}));
export const doOauthSignIn = (type, app, location, emailHint) => {
    const payload = {
        app,
        locale: i18n.language,
        state: JSON.stringify(Object.assign(Object.assign({}, Object.fromEntries(new URLSearchParams(location.search).entries())), { app })),
    };
    if (emailHint)
        payload.login_hint = emailHint;
    const form = document.createElement("form");
    form.style.visibility = "hidden";
    form.method = "POST";
    form.action = `/api/v1/users/auth/${encodeURI(type)}`;
    Object.entries(payload).forEach(([k, v]) => {
        const input = document.createElement("input");
        input.name = k;
        input.value = v;
        form.appendChild(input);
    });
    document.body.appendChild(form);
    form.submit();
};
const SocialLogins = (props) => {
    const { app } = props;
    const { classes } = useStyles();
    const { t } = useTranslation("auth");
    const location = useLocation();
    const doThirdPartySignIn = useCallback((type) => {
        doOauthSignIn(type, app, location, null);
    }, [app, location]);
    const userFacebookSignIn = useCallback(() => doThirdPartySignIn("facebook"), [doThirdPartySignIn]);
    const userGoogleSignIn = useCallback(() => doThirdPartySignIn("google_oauth2"), [doThirdPartySignIn]);
    const userMicrosoftSignIn = useCallback(() => doThirdPartySignIn("microsoft_graph"), [doThirdPartySignIn]);
    const userTwitterSignIn = useCallback(() => doThirdPartySignIn("twitter"), [doThirdPartySignIn]);
    const userAppleSignIn = useCallback(() => doThirdPartySignIn("apple"), [doThirdPartySignIn]);
    return (_jsx(_Fragment, { children: enableSocialLogins() && (_jsxs(Grid, { item: true, xs: 12, container: true, alignItems: "center", justifyContent: "flex-start", spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h1", component: "h2", children: t("add.connect") }) }), REACT_APP_OAUTH_FACEBOOK === "y" && (_jsx(Grid, { item: true, children: _jsx(IconButton, { className: classes.socialLogin, onClick: userFacebookSignIn, size: "large", children: _jsx(Facebook, {}) }) })), REACT_APP_OAUTH_GOOGLE === "y" && (_jsx(Grid, { item: true, children: _jsx(IconButton, { className: classes.socialLogin, onClick: userGoogleSignIn, size: "large", children: _jsx(Google, {}) }) })), REACT_APP_OAUTH_MICROSOFT === "y" && (_jsx(Grid, { item: true, children: _jsx(IconButton, { className: classes.socialLogin, onClick: userMicrosoftSignIn, size: "large", children: _jsx(Microsoft, {}) }) })), REACT_APP_OAUTH_TWITTER === "y" && (_jsx(Grid, { item: true, children: _jsx(IconButton, { className: classes.socialLogin, onClick: userTwitterSignIn, size: "large", children: _jsx(Twitter, {}) }) })), REACT_APP_OAUTH_APPLE === "y" && (_jsx(Grid, { item: true, children: _jsx(IconButton, { className: classes.socialLogin, onClick: userAppleSignIn, size: "large", children: _jsx(Apple, {}) }) }))] })) }));
};
export default React.memo(SocialLogins);
